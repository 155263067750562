<template>
  <v-col v-if="input.visible" cols="12" :md="input.md" :lg="input.lg" :style="stylePadding">
    <component v-bind:is="component" v-bind="bindProps" :items="items" v-model="myValue" :error="hasError" :error-messages="errorMessages" @save="$emit('save')">
      <template v-slot:label v-if="htmlLabel !== false && input.input !== 'radio'">
        <div v-html="htmlLabel"></div>
      </template>
      <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData"></slot>
      </template>
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
        <slot :name="slotName"></slot>
      </template>
      <template v-if="input.slots && input.slots.item" v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-if="input.slots.item.title">{{ item[input.slots.item.title] }}</v-list-item-title>
          <v-list-item-subtitle v-if="input.slots.item.subtitle">{{ item[input.slots.item.subtitle] }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </component>
  </v-col>
</template>
<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0
}
</style>
<script>
import { ref, reactive, computed, watch } from '@vue/composition-api'

export default {
  components: {
    VTextField: () => import('vuetify/lib/components/VTextField'),
    VTextarea: () => import('vuetify/lib/components/VTextarea'),
    VFileInput: () => import('vuetify/lib/components/VFileInput'),
    VSelect: () => import('vuetify/lib/components/VSelect'),
    VCombobox: () => import('vuetify/lib/components/VCombobox'),
    VAutocomplete: () => import('vuetify/lib/components/VAutocomplete'),
    // VRadio: () => import('vuetify/lib/components/VRadioGroup'),
    VCheckbox: () => import('vuetify/lib/components/VCheckbox/VCheckbox'),
    VSwitch: () => import('vuetify/lib/components/VSwitch'),
    Slider: () => import('./inputs/Slider'),
    VRangeSlider: () => import('vuetify/lib/components/VRangeSlider'),
    VDivider: () => import('vuetify/lib/components/VDivider'),
    DatePicker: () => import('./inputs/DatePicker'),
    TimePicker: () => import('./inputs/TimePicker'),
    Radio: () => import('./inputs/Radio'),
    ChurchSearch: () => import('./inputs/ChurchSearch'),
    DirectorySearch: () => import('./inputs/DirectorySearch'),
    HtmlBlock: () => import('./inputs/HTMLBlock'),
    MaskedText: () => import('./inputs/MaskedText'),
    SignaturePad: () => import('./inputs/SignaturePad'),
    FileUpload: () => import('./inputs/FileUpload')
  },
  props: {
    input: {
      type: Object,
      default: () => {
        return {}
      }
    },
    value: {
      type: [ String, Number, Object, Array, Boolean, File ]
    },
    hasError: {
      type: Boolean,
      default: null
    },
    errorMessages: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup (props, { root, emit }) {
    const myValue = ref(props.value)
    const asteriskOnRequired = computed(() => root.$store.state.forms.formSettings.asteriskOnRequired)

    watch(() => props.value, () => {
      if (props.value != null && props.value !== myValue.value) {
        myValue.value = props.value
      }
    })

    watch(myValue, (newVal, oldVal) => {
      if (newVal == null && oldVal != null && props.input.input !== 'checkbox') {
        return
      }
      if (props.input.input !== 'checkbox' && props.input.input !== 'switch' && newVal === false) return

      emit('input', myValue.value)
      // if ('name' in props.input) emit('update', { field: props.input.name, value: myValue.value })
      // else
      emit('update', { field: props.input.label, value: myValue.value })
    })

    const bindProps = reactive({})
    const items = ref([])

    const component = computed(() => {
      if (props.input && 'input' in props.input) {
        switch (props.input.input) {
          case 'text':
            if ('text' in props.input && 'mask' in props.input.text && props.input.text.mask != null && props.input.text.mask !== '') return 'MaskedText'
            return 'VTextField'
          case 'email':
            return 'VTextField'
          case 'number':
            return 'VTextField'
          case 'textarea':
            return 'VTextarea'
          case 'file':
            return 'VFileInput'
          case 'file-upload':
            return 'FileUpload'
          case 'select':
            return 'VSelect'
          case 'combobox':
            return 'VCombobox'
          case 'autocomplete':
            return 'VAutocomplete'
          case 'radio':
            return 'Radio'
          case 'checkbox':
            return 'VCheckbox'
          case 'switch':
            return 'VSwitch'
          case 'slider':
            return 'Slider'
          case 'rangeSlider':
            return 'VRangeSlider'
          case 'divider':
            return 'VDivider'
          case 'datePicker':
            return 'DatePicker'
          case 'timePicker':
            return 'TimePicker'
          case 'church-search':
            return 'ChurchSearch'
          case 'directory-search':
            return 'DirectorySearch'
          case 'html-block':
            return 'HtmlBlock'
          case 'signature':
            return 'SignaturePad'
        }
      }
      return 'div'
    })

    const htmlLabel = computed(() => {
      if ('htmlLabel' in props.input) {
        if ('required' in props.input && props.input.required && (asteriskOnRequired.value === 'true' || asteriskOnRequired.value === true)) {
          return props.input.htmlLabel + '<span class="error--text fas fa-asterisk" style="font-size:.6em;transform:translateY(-.6em);margin-left:.2em"></span>'
        }
        return props.input.htmlLabel
      } else if (props.input.required && (asteriskOnRequired.value === 'true' || asteriskOnRequired.value === true)) {
        return props.input.label + '<span class="error--text fas fa-asterisk" style="font-size:.6em;transform:translateY(-.6em);margin-left:.2em"></span>'
      }
      return false
    })

    watch(() => props.input, () => {
      for (let l in props.input) {
        bindProps[l] = props.input[l]
      }
      // bindProps.label = props.input.label
      bindProps.rules = []
      if ('required' in props.input && props.input.required) bindProps.rules.push(v => !!v || props.input.requiredText || 'Required')
      switch (props.input.input) {
        case 'text':
          if (props.input.label === 'Banner ID' && root.$store.state.loggedIn && root.$store.state.user != null && 'email' in root.$store.state.user && /^[a-z.]+@covenant.edu$/i.test(root.$store.state.user.email) && (myValue.value === '' || myValue.value == null)) {
            myValue.value = root.$store.state.user.bannerId
          }
          if ('min' in props.input && props.input.min != null && !isNaN(props.input.min)) {
            bindProps.rules.push(v => (!!v && v.length >= props.input.min) || 'Min Length: ' + props.input.min)
          }
          if ('max' in props.input && props.input.max != null && !isNaN(props.input.max)) {
            bindProps.rules.push(v => (!!v && v.length <= props.input.max) || 'Max Length: ' + props.input.max)
            bindProps.counter = true
            bindProps.maxLength = props.input.max
          }
          if ('text' in props.input && 'mask' in props.input.text) bindProps.mask = props.input.text.mask
          if ('text' in props.input && 'regex' in props.input.text) {
            bindProps.rules.push(v => {
              return new RegExp(props.input.text.regex).test(v) || 'Invalid Entry'
            })
          }
          break
        case 'email':
          if ('email' in props.input && 'COV' in props.input.email && props.input.email.COV) {
            bindProps.rules.push(v => /^[a-z.]+@covenant.edu$/i.test(v) || 'Must be a Covenant email including @covenant.edu')
            if (root.$store.state.loggedIn && root.$store.state.user != null && 'email' in root.$store.state.user && /^[a-z.]+@covenant.edu$/i.test(root.$store.state.user.email) && (myValue.value === '' || myValue.value == null)) {
              myValue.value = root.$store.state.user.email
            }
          } else {
            bindProps.rules.push(v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Invalid email')
          }
          break
        case 'number':
          bindProps.type = 'number'
          if ('min' in props.input && props.input.min != null && !isNaN(props.input.min)) {
            bindProps.rules.push(v => v >= props.input.min || props.input.minText || 'Minimum: ' + props.input.min)
          }
          if ('max' in props.input && props.input.max != null && !isNaN(props.input.max)) {
            bindProps.rules.push(v => v <= props.input.max || props.input.maxText || 'Maximum: ' + props.input.max)
          }
          if ('number' in props.input && 'step' in props.input.number && props.input.number.step != null && !isNaN(props.input.number.step)) {
            bindProps.step = props.input.number.step
          }
          break
        case 'textarea':
          bindProps.rows = 2
          bindProps.autoGrow = true
          if ('textarea' in props.input && 'word' in props.input.textarea && props.input.textarea.word) {
            if ('min' in props.input && props.input.min != null && !isNaN(props.input.min)) {
              bindProps.rules.push(v => v.trim().split(/\s+/).length >= props.input.min || props.input.minText || 'Min Length: ' + props.input.min)
            }
            if ('max' in props.input && props.input.max != null && !isNaN(props.input.max)) {
              bindProps.rules.push(v => v.trim().split(/\s+/).length <= props.input.max || props.input.maxText || 'Max Length: ' + props.input.max)
            }
          } else {
            if ('min' in props.input && props.input.min != null && !isNaN(props.input.min)) {
              bindProps.rules.push(v => v.length >= props.input.min || props.input.minText || 'Min Length: ' + props.input.min)
            }
            if ('max' in props.input && props.input.max != null && !isNaN(props.input.max)) {
              bindProps.rules.push(v => v.length <= props.input.max || props.input.maxText || 'Max Length: ' + props.input.max)
              bindProps.counter = true
            }
          }
          break
        case 'select':
        case 'combobox':
        case 'autocomplete':
        case 'radio':
          if (props.input.options) {
            root.$feathers.service('forms/select-options').get(props.input.options).then(({ options, service, query, textField, valueField }) => {
              if (options.length > 0) {
                items.value = options
              } else {
                try {
                  let dt = new Date()
                  query = JSON.parse(query.replace('_now_', dt.toISOString()))
                } catch (err) {
                  // console.error(err)
                  return
                }
                root.$feathers.service(service).find({ query }).then((data) => {
                  if ('data' in data) {
                    data = data.data
                  }
                  let arr = []
                  data.forEach((row) => { arr.push({ text: row[textField], value: row[valueField] }) })
                  items.value = arr
                })
              }
            })
          } else if (props.input.items) {
            items.value = props.input.items
          } else if (props.input.optionGroup) {
            root.$feathers.service('forms/select-options').find({ query: { name: props.input.optionGroup } }).then(({ data }) => {
              root.$feathers.service('forms/select-options').get(data[0].value).then(({ options }) => {
                items.value = options
              })
            })
          }
          if ('select' in props.input && 'multiple' in props.input.select && props.input.select.multiple) {
            bindProps.multiple = true
            if ('min' in props.input) {
              bindProps.rules.push(v => v.length >= props.input.min || 'Select at least: ' + props.input.min)
            } else if ('required' in props.input && props.input.required) {
              bindProps.rules.push(v => v.length > 0 || 'Required')
            }
            if ('max' in props.input) {
              bindProps.rules.push(v => v.length <= props.input.max || 'Select no more than: ' + props.input.max)
            }
          }
          break
        case 'slider':
        case 'rangeSlider':
          if (!('min' in props.input)) bindProps.min = 0
          if (!('max' in props.input)) bindProps.max = 100
          break
        case 'datePicker':
          break
        case 'timePicker':
          break
        case 'file':
          bindProps.accept = props.input.file.fileTypes
          break
        case 'file-upload':
          bindProps.folder = props.input.file.folder
          bindProps.accept = props.input.file.fileTypes
          bindProps.multiple = props.input.file.multiple
          bindProps.subfolder = props.input.file.subfolder
          break
        case 'signature':
          if ('signature' in props.input) {
            bindProps.title = props.input.signature.title || ''
            bindProps.showEnterName = props.input.signature.showEnterName || false
            bindProps.buttonColor = props.input.signature.buttonColor || ''
          } else {
            bindProps.title = ''
            bindProps.showEnterName = false
            bindProps.buttonColor = ''
          }
          break
        case 'directory-search':
          if ('dirSearch' in props.input) {
            for (const l in props.input.dirSearch) {
              bindProps[l] = props.input.dirSearch[l]
            }
          }
      }
      if ('rules' in props.input) {
        for (let i = 0; i < props.input.rules.length; i++) {
          bindProps.rules.push(props.input.rules[i])
        }
      }
      bindProps.value = props.value || ''
      bindProps.outlined = true
    })

    const stylePadding = computed(() => {
      switch (props.input.input) {
        case 'block':
        case 'search':
        case 'church-search':
        case 'college-search':
        case 'highschool-search':
          return 'padding:0'
      }
    })

    return {
      myValue,
      asteriskOnRequired,
      bindProps,
      items,
      component,
      htmlLabel,
      stylePadding
    }
  }
}
</script>
